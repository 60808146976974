<template>
  <div>
    <v-toolbar color="#f8f8f8" dense elevation="1">
      <v-toolbar-title class="secondary--text text-subtitle-2 text-capitalize">
        {{ $t('myAccountTranslation') }}
      </v-toolbar-title>
    </v-toolbar>

    <v-container>
      <EmployeeDetailsCard :employee="employeeData"></EmployeeDetailsCard>
    </v-container>
  </div>
</template>

<script>
import EmployeeDetailsCard from '@/components/EmployeeDetailsCard.vue';

export default {
  name: 'MyAccount',
  components: {
    EmployeeDetailsCard,
  },

  data: () => ({
    drawer: null,
  }),

  computed: {
    initials() {
      return (
        this.$store.state.currentUser.first_name[0] +
        this.$store.state.currentUser.first_name[0]
      );
    },
    user() {
      return this.$store.state.currentUser || {};
    },

    employeeData() {
      return this.$store.state.employeeData || {};
    },
    fullName() {
      return this.employeeData.first_name + ' ' + this.employeeData.last_name;
    },
  },
};
</script>
